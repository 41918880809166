import { deepCopy } from '@util/functions/objects';
import { BehaviorSubject } from 'rxjs';

/**
 * BehaviorSubject that saves states in a stream and allows to undo and redo these states
 */
export class UndoBehaviorSubject<T = any> extends BehaviorSubject<T> {

  private _valueStream: T[] = [];
  // _p = pointer
  private _p = 0;
  private useDeepCopy = false;

  constructor(init: T, opt?: {useDeepCopy: boolean;}) {
    super(init);
    if (opt) {
      this.useDeepCopy = opt.useDeepCopy;
    }
    this._valueStream.push(init);
  }

  /**
   * number of possible undos
   */
  get howManyUndos(): number {
    return this._p;
  }

  /**
   * number of possible redos
   */
  get howManyRedos(): number {
    return this._valueStream.length - 1 - this._p;
  }


  /**
   * adds a new value to the undo stack - destroys redo stack
   */
  override next(value: T) {
    if (this.useDeepCopy) {
      value = deepCopy(value);
    }
    this._p++;
    const arr = this._valueStream.slice(0, this._p);
    arr.push(value);
    this._valueStream = arr;
    this._p = arr.length - 1;
    super.next(value);
  }

  /**
   * replaces the last value of the undo stack with given one - by default: destroys redo stack
   */
  replace(value: T, keepRedo?: boolean) {
    if (keepRedo) {
      this._valueStream.splice(this._p, 1, value);
    } else {
      const arr = this._valueStream.slice(0, this._p);
      arr.push(value);
      this._valueStream = arr;
      this._p = arr.length - 1;
    }
    super.next(value);
  }

  undo(): number {
    return this.undoMany(1);
  }

  redo() {
    return this.redoMany(1);
  }

  update(fn: (current: T) => T) {
    const cur = this.useDeepCopy ? deepCopy(this.getValue()) : this.getValue();
    this.next(fn(cur));
  }

  /**
   * undos the number of given steps and returns the number of steps it was possible to return
   * force = true, it undos as many steps as possible if not the full amount of given steps
   */
  undoMany(many: number, force?: boolean) {

    // 0 or negative steps cannot be undone
    if (many <= 0) {
      return 0;
    }

    // if more wanted than possible - only if force is true and not more than possible
    if (this.howManyUndos < many && force) {
      const possibleSteps = this.howManyUndos;
      this._undoMany(possibleSteps);
      return possibleSteps;
    }

    // if wanted steps with in the possible
    if (many <= this.howManyUndos) {
      this._undoMany(many);
      return many;
    }

    return -1;
  }

  redoMany(many: number, force?: boolean): number {

    if (many <= 0) {
      return 0;
    }

    if (this.howManyRedos < many && force) {
      const possibleSteps = this.howManyRedos;
      this._redoMany(possibleSteps);
      return possibleSteps;
    }

    if (many <= this.howManyRedos) {
      this._redoMany(many);
      return many;
    }

    return -1;
  }

  /**
   * undos the subjects until the given function is true
   * note: if the given function is already true with the current value -> it does not undo and returns 0
   * note: if the given function never gets true -> it does not undo and returns -1
   */
  undoUntil(untilTrueFn: (val: T) => boolean, startingStep: number = 0) {
    const steps = this.getHowManyUndoUntil(untilTrueFn, startingStep);

    if (steps > 0) {
      this.undoMany(steps);
    }

    return steps;
  }

  /**
   * gets the number of steps it has to undo until a given function would be true.
   * if the given function never gets true it returns -1
   */
  getHowManyUndoUntil(untilTrueFn: (val: T, stackIndex: number) => boolean, startingStep: number = 0): number {

    let stop = false;
    let steps = startingStep;

    const history = this.getHistory();
    const historyStack = history.historyStack;
    const pointer = history.pointer;

    for (let i = pointer - startingStep; i >= 0 && !stop; i--) {
      stop = untilTrueFn(historyStack[i], steps);
      if (!stop) {
        steps++;
      }
    }

    return stop ? steps : -1;

  }

  /**
   * @returns complete history of all values without pointer towards current value.
   * Note: this may also include redo stack
   */
  getValueHistory(): T[] {
    return this.getHistory().historyStack;
  }

  /**
   * @returns history pointer towards current value
   */
  getHistoryPointer(): number {
    return this.getHistory().pointer;
  }

  getHistory() {
    return {
      historyStack: [...this._valueStream],
      pointer: this._p
    };
  }


  getLastValue(step: number = 1): T {
    const h = this.getHistory();
    if ((h.pointer - step) >= 0) {
      return h.historyStack[h.pointer - step];
    }
    return null;
  }


  getLastDistinctValue(equalFn?: (a: T, b: T) => boolean): T {
    const history = this.getHistory();
    const historyStack = history.historyStack;
    const pointer = history.pointer;

    if (pointer < 1) {
      return null;
    }

    if (!equalFn) {
      equalFn = (a, b) => (a === b);
    }

    const curVal = historyStack[pointer];
    let returnVal: T = null;

    for (let i = pointer; !returnVal && i >= 0; i--) {
      const loopVal = historyStack[i];
      if (!equalFn(curVal, loopVal)) {
        returnVal = loopVal;
      }
    }
    return returnVal;
  }

  private internalNext(value: T) {
    super.next(value);
  }

  private _undoMany(steps: number) {
    this._p -= steps;
    this.internalNext(this._valueStream.at(this._p));
  }

  private _redoMany(steps: number) {
    this._p += steps;
    this.internalNext(this._valueStream.at(this._p));
  }

}
